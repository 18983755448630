import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import anagrafica from './routes/anagrafica'
// import biglietteria from './routes/biglietteria'
// import contabilita from './routes/contabilita'
// import contratti from './routes/contratti'
import dashboard from './routes/dashboard'
// import destinatari from './routes/destinatari'
// import produzioni from './routes/produzioni'
// import scadenze from './routes/scadenze'
// import sviluppo from './routes/sviluppo'
import pages from './routes/pages'
//import demo from './routes/demo'
import configurazioni from './routes/configurazioni'
import vendite from './routes/vendite'
import tabelle from './routes/tabelle'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'loader' } },
    ...configurazioni,
    ...vendite,
    ...tabelle,
    // ...anagrafica,
    // ...biglietteria,
    // ...contabilita,
    // ...contratti,
    ...dashboard,
    // ...destinatari,
    ...pages,
    // ...produzioni,
    // ...scadenze,
    // ...sviluppo,
    //...demo,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // allow access to registration page?
  if (isLoggedIn === false && to.name === 'auth-register') {
    return next()
  }

  //console.log('logged in: ' + isLoggedIn)
  //console.log('redirect to: ' + to.name)
  if (isLoggedIn === false && to.name !== 'auth-login') {
    return next({ name: 'auth-login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    // const userData = getUserData()
    // next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    next('/')
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    console.log(`Rifiutato accesso a ${to.name}`)
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
