export default [  
    {
    path: '/tabelleApplicazioneCompensi',
    name: 'tabelleApplicazioneCompensi',
    component: () => import('@/views/tabelle/PaginaTabelleApplicazioneCompensi.vue'),
    meta: {
      resource: 'tabelle',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tabelle Applicazione Compensi',
        },
        {
          text: 'Elenco Tabelle Applicazione Compensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tabelleCommissionPunti',
    name: 'tabelleCommissionPunti',
    component: () => import('@/views/tabelle/PaginaTabelleCommissionEPunti.vue'),
    meta: {
      resource: 'tabelle',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tabelle Commission e Punti',
        },
        {
          text: 'Elenco Tabelle Commission e Punti',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tabelleCompensi',
    name: 'tabelleCompensi',
    component: () => import('@/views/tabelle/PaginaTabelleCompensi.vue'),
    meta: {
      resource: 'tabelle',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tabelle Compensi',
        },
        {
          text: 'Elenco Tabelle Compensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tabelleGaraService',
    name: 'tabelleGaraService',
    component: () => import('@/views/tabelle/PaginaTabelleGettoniGaraAddettiService.vue'),
    meta: {
      resource: 'tabelle',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tabelle Gara Service',
        },
        {
          text: 'Elenco Tabelle Gara Service',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tabelleImportiBanco',
    name: 'tabelleImportiBanco',
    component: () => import('@/views/tabelle/PaginaTabelleImportiAttivitaBanco.vue'),
    meta: {
      resource: 'tabelle',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tabelle Importi Attività Banco',
        },
        {
          text: 'Elenco Tabelle Importi Attività Banco',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tabellePremiF100',
    name: 'tabellePremiF100',
    component: () => import('@/views/tabelle/PaginaTabelleSogliePremiAddettiF100.vue'),
    meta: {
      resource: 'tabelle',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tabelle Premi F100',
        },
        {
          text: 'Elenco Tabelle Premi F100',
          active: true,
        },
      ],
    },
  },
]