import store from '@/store/index'

function displayRoute(route)
{
  console.log(route.meta.action)
  console.log(route.meta.resource)
}

export const canNavigate = to => {
  // TODO: usare emit per notificare cambiamento al momento del login
  const userAbility = store.state.user.ability

  if (!to.matched[0].meta.resource) {
    // se resource e' undefined
    console.log(to.matched[0].meta.resource)
    console.log(to.matched[0].name)
    return false
  }
  // console.log(ability.can(to.matched[0].meta.action, to.matched[0].meta.resource))
  // const result = to.matched.some(evaluateRoute)
  // console.log(`canNavigate to ${to.name}: ${result}`)
  // userAbility.forEach(ab => console.log(`res: ${ab.resource} act: ${ab.action}`))

  // return to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
  return to.matched.some(function (route) {
    const permesso = userAbility.some(a => route.meta.resource === a.resource)
    // console.log(permesso)
    return permesso
  })
}

export const _ = undefined
