export default [
  {
    path: '/home',
    name: 'home',
    meta: {
      resource: 'pages',
      action: 'read',
    },
    component: () => import('@/views/dashboard/cruscotto/PaginaHome.vue'),
  },
  {
    path: '/loader',
    name: 'loader',
    meta: {
      resource: 'pages',
      action: 'read',
    },
    component: () => import('@/views/dashboard/cruscotto/PaginaLoader.vue'),
  },
]
