export default [
  {
    path: '/vendite',
    name: 'vendite',
    component: () => import('@/views/vendite/PaginaVendite.vue'),
    meta: {
      resource: 'vendite',
      action: 'read',
      breadcrumb: [
        {
          text: 'Vendite',
        },
        {
          text: 'Elenco Vendite',
          active: true,
        },
      ],
    },
  },
  {
    path: '/attivitaBanco',
    name: 'attivitaBanco',
    component: () => import('@/views/vendite/PaginaAttivitaBanco.vue'),
    meta: {
      resource: 'vendite',
      action: 'read',
      breadcrumb: [
        {
          text: 'Attivita Banco',
        },
        {
          text: 'Elenco Attivita Banco',
          active: true,
        },
      ],
    },
  },
  
]