import jwtDefaultConfig from './jwtDefaultConfig'
import router from '@/router'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  base_url = process.env.VUE_APP_API_ENDPOINT

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    this.checkUrl = this.base_url + this.jwtConfig.checkEndpoint

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )


    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage. Nella risposta c'è scritto 'access'
              if(r.data.access) {
                this.setToken(r.data.access)
              } else {
                console.log("evito di mettere undefined in localstorage")
              }
              // aspetto un attimo prima di riprovare?
              setTimeout(() => { this.onAccessTokenFetched(r.data.access); }, 1000);
              // se abilito questo finisco in un loop in finito in cui nell'auth c'e' il token errato
            })
          }

          // retryOriginal solo se non e' metodo check
          const retryOriginalRequest = new Promise(resolve => {
            if(originalRequest.url !== this.checkUrl ) {
              this.addSubscriber(accessToken => {
                // Make sure to assign accessToken according to your response.
                // Check: https://pixinvent.ticksy.com/ticket/2413870
                // Change Authorization header

                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                resolve(this.axiosIns(originalRequest))
              })
            } else {
              console.log('Richiesta verify da non ripetere con vecchio token')
              // redirigere a pagina login
              router.push({ name: 'auth-login' })
            }
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    const loginUrl = this.base_url + this.jwtConfig.loginEndpoint
    return this.axiosIns.post(loginUrl, ...args)
  }

  get(path, param) {
    const url = this.base_url + path
    if (param === undefined) {
      return this.axiosIns.get(url, { crossdomain: true })
    } else {
      console.log('JWTSERVICE GET')
      console.log(param)
      return this.axiosIns.get(url, {
        crossdomain: true,
        params: param,
      })
    }
  }

  post(path, ...args) {
    const url = this.base_url + path
    return this.axiosIns.post(url, ...args)
  }

  put(path, ...args) {
    const url = this.base_url + path
    return this.axiosIns.put(url, ...args)
  }

  patch(path, ...args) {
    const url = this.base_url + path
    return this.axiosIns.patch(url, ...args)
  }

  delete(path, ...args) {
    const url = this.base_url + path
    return this.axiosIns.delete(url, ...args)
  }

  register(...args) {
    const registerUrl = this.base_url + this.jwtConfig.registerEndpoint
    return this.axiosIns.post(registerUrl, ...args)
  }

  check(...args) {
    const param = {'token': this.getToken()}

    // TODO: gestione promise per fare in modo che il chiamante aspetti
    return this.axiosIns.post(this.checkUrl, param).then(function (response) {
      console.log("STATUS JWT CHECK")
      console.log(response.status);
      // tutto ok
    });
  }

  refreshToken() {
    const refreshUrl = this.base_url + this.jwtConfig.refreshEndpoint
    return this.axiosIns.post(refreshUrl, {
      refresh: this.getRefreshToken(),
    })

  }
}
