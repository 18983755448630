const user = JSON.parse(localStorage.getItem('userData'));
const state = user
      ? {'ability': user.ability}
      : {'ability': [{'action': 'read', 'resource': 'Auth'}]};

export default {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    UPDATE_ABILITY(state, val) {
      state.ability = val
    },
  },
  actions: {},
}
