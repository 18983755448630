export default [
  {
    path: '/negozi',
    name: 'negozi',
    component: () => import('@/views/configurazioni/PaginaNegozi.vue'),
    meta: {
      resource: 'configurazioni',
      action: 'read',
      breadcrumb: [
        {
          text: 'Negozi',
        },
        {
          text: 'Elenco Negozi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/venditori',
    name: 'venditori',
    component: () => import('@/views/configurazioni/PaginaVenditori.vue'),
    meta: {
      resource: 'configurazioni',
      action: 'read',
      breadcrumb: [
        {
          text: 'Venditori',
        },
        {
          text: 'Elenco Venditori',
          active: true,
        },
      ],
    },
  },
  {
    path: '/presidi',
    name: 'presidi',
    component: () => import('@/views/configurazioni/PaginaPresidi.vue'),
    meta: {
      resource: 'configurazioni',
      action: 'read',
      breadcrumb: [
        {
          text: 'Presidi',
        },
        {
          text: 'Elenco Presidi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tipologieAttivitaBanco',
    name: 'tipologieAttivitaBanco',
    component: () => import('@/views/configurazioni/PaginaTipologieAttivitaBanco.vue'),
    meta: {
      resource: 'configurazioni',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipologie Attività Banco',
        },
        {
          text: 'Elenco Tipologie Attività Banco',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tipologieVendita',
    name: 'tipologieVendita',
    component: () => import('@/views/configurazioni/PaginaTipologieVendita.vue'),
    meta: {
      resource: 'configurazioni',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipologie Vendita',
        },
        {
          text: 'Elenco Tipologie Vendita',
          active: true,
        },
      ],
    },
  },
  {
    path: '/scenariVendita',
    name: 'scenariVendita',
    component: () => import('@/views/configurazioni/PaginaScenariVendita.vue'),
    meta: {
      resource: 'configurazioni',
      action: 'read',
      breadcrumb: [
        {
          text: 'Scenari Vendita',
        },
        {
          text: 'Elenco Scenari Vendita',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agenti',
    name: 'agenti',
    component: () => import('@/views/configurazioni/PaginaAgenti.vue'),
    meta: {
      resource: 'configurazioni',
      action: 'read',
      breadcrumb: [
        {
          text: 'Agenti',
        },
        {
          text: 'Elenco Agenti',
          active: true,
        },
      ],
    },
  },
]